import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class ChangeLocation extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){

}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="white_shadow_box" id="vegetables">
                <div className="my_profile">
                  <div className="profile__">
                    <h4>Hi, we need your phone number to verify your identity</h4>
                    <div className="form_box">
                      <label>Phone Number</label>
                      <input type="text" value="" placeholder="+92 - "/>
                      <h6>Phone number must start with 3 eg: 3xxxxxxxxx</h6>
                      <button type="button">Use SMS</button>
                      <button type="button" className="use_call">Use Call</button>
                      <p>*You will receive a call/sms shortly. Existing users will log in right after verifying the confirmation code</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default ChangeLocation;
