import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import easypaisa  from '../assets/images/easypaisa.png';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class Checkout extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){

}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="slider_head category_head">
                <h3>Wallet</h3>
              </div>
              <div className="white_shadow_box" id="vegetables">
                <div className="table-responsive order_received_box cart_table">
                  <table className="table-box">
                    <tbody>
                      <tr>
                      <td style={{FontWeight:'bold'}}>Personal Balance</td>
                      <td className="order_total fee_green">Rs. -299</td>
                    </tr>
                    <tr>
                      <td style={{FontWeight:'bold'}}>Available Balance</td>
                      <td className="order_total fee_green">Rs. -299</td>
                    </tr>
                    <tr>
                      <td style={{FontWeight:'bold'}}>Promotional Balance</td>
                      <td className="order_total fee_green">PKR 0</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>


          <section className="slider_warp">
            <div className="container">
              <div className="slider_head category_head">
                <h3>Cards</h3>
              </div>
              <div className="white_shadow_box" id="vegetables">
                <div className="table-responsive order_received_box cart_table">
                  <table className="table-box">
                    <tbody>
                      <tr>
                      <td className="change_address"><Link to="#"><h5><svg style={{width:'24px'}}  viewBox="0 0 24 24"><path d="M7 12h4V8h1v4h4v1h-4v4h-1v-4H7v-1zm4.5-9a9.5 9.5 0 1 1 0 19a9.5 9.5 0 0 1 0-19zm0 1a8.5 8.5 0 1 0 0 17a8.5 8.5 0 0 0 0-17z" fill="#626262"/></svg> Add a new Card</h5></Link></td>
                      <td className="order_total fee_green"><Link to="/add-card" className="change_info">Manage Card</Link></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>

        <section className="slider_warp">
        <div className="container">
          <div className="slider_head category_head">
            <h3>Current Payment Method</h3>
          </div>
          <div className="white_shadow_box" id="vegetables">
            <div className="table-responsive order_received_box cart_table">
              <table className="table-box">
                <tbody>
                  <tr>
                  <td style={{FontWeight:'bold'}}> <div className="gendar">
                      <input type="radio" id="test20" name="radio-group"/>
                      <label for="test20">Cash On Delivery (COD) <svg style={{width:'22px'}} viewBox="0 0 24 24"><path d="M11 4h1v2.01c3.29.141 4 1.685 4 2.99h-1c0-1.327-1.178-2-3.5-2c-.82 0-3.5.163-3.5 2.249c0 .872 0 1.86 3.621 2.766l1.606.485C15.76 13.435 16 14.572 16 15.751c0 1.881-1.518 3.093-4 3.235V21h-1v-2.01c-3.29-.141-4-1.685-4-2.99h1c0 1.327 1.178 2 3.5 2c.82 0 3.5-.163 3.5-2.249c0-.872 0-1.86-3.621-2.766L9.773 12.5C7.24 11.565 7 10.428 7 9.249c0-1.881 1.518-3.093 4-3.235V4z" fill="#626262"></path></svg></label>
                  </div> 
                  </td>
                  <td className="order_total fee_green"><svg style={{width:'30px'}} viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#51aa1a"></path></svg></td>
                </tr>
                <tr>
                  <td style={{FontWeight:'bold'}}> <div className="gendar">
                      <input type="radio" id="test21" name="radio-group"/>
                      <label for="test21"> Credit Card <svg style={{width:'22px'}} viewBox="0 0 24 24"><path d="M5 7h3V5l2-2h3l2 2v2h3a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3zm5.414-3L9 5.414V7h5V5.414L12.586 4h-2.172zM5 8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H5z" fill="#626262"></path></svg></label>
                  </div> 
                  </td>
                  <td className="order_total fee_green"><svg style={{width:'30px'}} viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#51aa1a"></path></svg></td>
                </tr>
                <tr>
                  <td style={{FontWeight:'bold'}}> <div className="gendar">
                      <input type="radio" id="test23" name="radio-group"/>
                      <label for="test23"> EasyPaisa <img className="easypasa" src={easypaisa} alt=""/> </label>
                  </div>  </td>
                  <td className="order_total fee_green" id="coming_soon">Coming Soon</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default Checkout;
