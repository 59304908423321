import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class ChangeLocation extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){

}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="white_shadow_box" id="vegetables">
                <div className="my_profile">
                  <div className="change_location">
                    <h4>Delivery location</h4>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3372.987221546196!2d72.27823066450415!3d32.285321516201435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sayan%20plaza%20first%20floor%20janoobi%20bazar%20near%20Jauharabad!5e0!3m2!1sen!2s!4v1614927785650!5m2!1sen!2s" width="100%" height="300" style={{margin:'0 0 30px 0',display:'block',border:'0'}} allowfullscreen="" loading="lazy"></iframe>
                    <div className="form_box">
                      <button type="button">Proceed</button>
                      <button type="button" className="use_call">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default ChangeLocation;
