import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import footerlogo  from '../assets/images/clud-grocer.png';
import milkcarton  from '../assets/images/grocerapp-1ltr-x12-olper-milk-carton-6030a2ea5eb4d.webp';
import junior  from '../assets/images/grocerapp-molfix-5-junior-11-18kg-5f4639e3a6769.webp';
import olpermilk  from '../assets/images/grocerapp-1ltr-x12-olper-milk-carton-6030a2ea5eb4d.webp';
import Header from './Header';
import Footer from './Footer';
import $ from 'jquery';
class Cart extends Component {


  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){

}

  render(){

    return (

        <React.Fragment>
          <Header />

        <section className="slider_warp">
        <div className="container">
          <div className="slider_head category_head">
            <h3>My Cart (4 items)</h3>
          </div>
          <div className="white_shadow_box" id="vegetables">
            <div className="table-responsive order_received_box cart_table">
              <table className="table-box">
                <tbody>
                  <tr>
                  <td>Sub Total</td>
                  <td className="order_total">Rs. 9927</td>
                </tr>
                <tr>
                  <td>Delivery Charges</td>
                  <td className="order_total fee_green">Free</td>
                </tr>
                <tr>
                  <td>Pending Payment</td>
                  <td className="order_total">PKR -299</td>
                </tr>
                <tr>
                  <td>
                    <h5>Total bill</h5>
                    <h5>Your total savings</h5>
                  </td>
                  <td className="order_total">
                    <h5>Rs. 10226</h5>
                    <b className="red_col">Rs. 2063</b>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <div className="membership_select_plan">
        <Link  to="/membership">
        <div className="club_box">
          <img src={footerlogo} alt=""/>
          <h4>Order Express Membership</h4>
          <p>3 Month</p>
          <h6>299 PKR <span>499 PKR</span></h6>
          <svg className="jss314 jss315 jss322" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path fill="none" d="M0 0h24v24H0z"></path></svg>
        </div>
        </Link>
      </div>


      <section className="slider_warp">
          <div className="container">
            <div className="white_shadow_box" id="cart_items_table">
              <div className="table-responsive order_received_box cart_table">
                <table className="table-box">
                  <tbody>
                    <tr>
                    <td className="img_cart">
                      <div className="slider_info">
                        <img src={milkcarton} alt=""/>
                    </div>
                    </td>
                    <td>
                      <div className="slider_info">
                        <div className="percent_off">
                        <b>10% OFF</b>
                      </div>
                      <h4>1.5LtrX8 Olpers Milk Carton</h4>
                      <p>8x1.5 Litre</p>
                      <h6>Rs. 1748 <span>Rs. 1920 × </span> 2 </h6>
                      <h6>Rs. 1637</h6>
                    </div>
                    </td>
                    <td className="last_cart_box">
                      <svg className="jss314 jss868" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                      <div className="quantity_box">
                         <input type="button" value="-" className="qty-minus minus_plus"/>
                         <input type="text" className="value_box" value="1"/>
                         <input type="button" value="+" className="qty-plus minus_plus"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="img_cart">
                      <div className="slider_info">
                        <img src={junior} alt=""/>
                    </div>
                    </td>
                    <td>
                      <div className="slider_info">
                        <div className="percent_off">
                        <b>10% OFF</b>
                      </div>
                      <h4>Molfix 5 Junior (11-18)kg</h4>
                      <p>1 unit</p>
                      <h6>Rs. 1748 <span>Rs. 1920 × </span> 2 </h6>
                      <h6>Rs. 3196</h6>
                    </div>
                    </td>
                    <td className="last_cart_box">
                      <svg className="jss314 jss868" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                      <div className="quantity_box">
                         <input type="button" value="-" className="qty-minus minus_plus"/>
                         <input type="text" className="value_box" value="1"/>
                         <input type="button" value="+" className="qty-plus minus_plus"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="img_cart">
                      <div className="slider_info">
                        <img src={olpermilk} alt=""/>
                    </div>
                    </td>
                    <td>
                      <div className="slider_info">
                        <div className="percent_off">
                        <b>10% OFF</b>
                      </div>
                      <h4>1Ltr x12 Olper Milk Carton</h4>
                      <p>12x1 Litre</p>
                      <h6>Rs. 1748 <span>Rs. 1920 × </span> 2 </h6>
                      <h6>Rs. 3496</h6>
                    </div>
                    </td>
                    <td className="last_cart_box">
                      <svg className="jss314 jss868" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                      <div className="quantity_box">
                         <input type="button" value="-" className="qty-minus minus_plus"/>
                         <input type="text" className="value_box" value="1"/>
                         <input type="button" value="+" className="qty-plus minus_plus"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>      
            </div>
          </div>
        </section>


        <div className="procced_checkout">
          <Link to="/checkout"><div className="row">
            <div className="col-sm-6 checkout_info">
              <h4>Proceed to Checkout</h4>
            </div>
            <div className="col-sm-6 checkout_info checkout_value">
              <h6>Rs. 10226</h6>
            </div>
          </div></Link>
        </div>

          <Footer />
        </React.Fragment>
    )
}
}
export default Cart;
