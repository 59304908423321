import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import $ from 'jquery';
class Myorders extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){


}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="slider_head category_head">
                <h3>My Orders</h3>
              </div>
              <div className="white_shadow_box" id="vegetables">
                <div className="table-responsive order_received_box">
                  <table className="table-box">
                    <tbody>
                      <tr>
                      <td><svg className="jss314 jss1168" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><g><path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"></path></g></svg> Fri, 5th Mar 2021</td>
                      <td className="order_total"><svg className="jss314 jss1168" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><g><path d="M14 1h-4c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1zm-2 13c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm7.03-6.61l.75-.75c.38-.38.39-1.01 0-1.4l-.01-.01c-.39-.39-1.01-.38-1.4 0l-.75.75C16.07 4.74 14.12 4 12 4c-4.8 0-8.88 3.96-9 8.76C2.87 17.84 6.94 22 12 22c4.98 0 9-4.03 9-9 0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path></g></svg> 6:00 pm - 9:00 pm</td>
                    </tr>
                    <tr>
                      <td>
                        <h5 style={{FontWeight:'bold'}}>Order #460335</h5>
                        <h5>Payment Mode: CoD</h5>
                      </td>
                      <td className="order_total order_details">
                        <h5>3 item(s)</h5>
                        <b>Rs. 707</b>
                        <Link to="/order-details" className="change_info"> <i className="fa fa-paper-plane"></i> Order Detail</Link>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="order_received">
                    <svg className="jss314 jss1221 jss1219" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zM8.5 14.21c0 .43-.36.79-.79.79-.25 0-.49-.12-.64-.33L4.75 11.5v2.88c0 .35-.28.62-.62.62s-.63-.28-.63-.62V9.79c0-.43.36-.79.79-.79h.05c.26 0 .5.12.65.33l2.26 3.17V9.62c0-.34.28-.62.63-.62s.62.28.62.62v4.59zm5-4.57c0 .35-.28.62-.62.62H11v1.12h1.88c.35 0 .62.28.62.62v.01c0 .35-.28.62-.62.62H11v1.11h1.88c.35 0 .62.28.62.62 0 .35-.28.62-.62.62h-2.53c-.47 0-.85-.38-.85-.85v-4.3c0-.45.38-.83.85-.83h2.53c.35 0 .62.28.62.62v.02zm7 4.36c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1V9.62c0-.34.28-.62.62-.62s.62.28.62.62v3.89h1.13v-2.9c0-.35.28-.62.62-.62s.62.28.62.62v2.89h1.12V9.62c0-.35.28-.62.62-.62s.62.28.62.62V14z"></path></svg>
                    <b>You order has been received</b>
                  </div>
                </div>

                <div className="table-responsive order_received_box">
                  <table className="table-box">
                    <tbody>
                      <tr>
                      <td><svg className="jss314 jss1168" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><g><path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"></path></g></svg> Fri, 5th Mar 2021</td>
                      <td className="order_total"><svg className="jss314 jss1168" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><g><path d="M14 1h-4c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1zm-2 13c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm7.03-6.61l.75-.75c.38-.38.39-1.01 0-1.4l-.01-.01c-.39-.39-1.01-.38-1.4 0l-.75.75C16.07 4.74 14.12 4 12 4c-4.8 0-8.88 3.96-9 8.76C2.87 17.84 6.94 22 12 22c4.98 0 9-4.03 9-9 0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path></g></svg> 6:00 pm - 9:00 pm</td>
                    </tr>
                    <tr>
                      <td>
                        <h5 style={{FontWeight:'bold'}}>Order #460335</h5>
                        <h5>Payment Mode: CoD</h5>
                      </td>
                      <td className="order_total order_details">
                        <h5>3 item(s)</h5>
                        <b>Rs. 707</b>
                        <Link to="/order-details" className="change_info"><i className="fa fa-paper-plane"></i> Order Detail</Link>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="order_received">
                    <b>Your order has been marked confirmed</b>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default Myorders;
