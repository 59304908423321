import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import visa  from '../assets/images/visa.jpg';
import mastercard  from '../assets/images/mastercard.jpg';
import amex  from '../assets/images/amex.jpg';
import Header from './Header';
import Footer from './Footer';
import $ from 'jquery';
class AddCard extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){

}

  render(){

    return (

        <React.Fragment>
          <Header />

      <section className="slider_warp">
        <div className="container">
          <div className="white_shadow_box" id="vegetables">
            <div className="my_profile">
              <div className="profile__">
                <h4>Add card Details</h4>
                <div className="form_box">
                  <div className="inner_box">
                      <div id="collapsedefult" className="collapse one" data-parent="#accordion" style={{display:'block'}} >
                          <div className="card-body1">
                             <div className="creditCardForm">
                              <div className="payment">
                                  <form>
                                      <div className="form-group form_box" id="card-number-field">
                                        <label>Card Number</label>
                                          <input type="text" placeholder="" className="form-control" id="cardNumber"/>
                                      </div>
                                      <div className="form-group  form_box owner">
                                        <label>Card Holder Name</label>
                                          <input type="text" placeholder="" className="form-control" id="owner"/>
                                      </div>
                                      <div className="form-group  form_box CVV">
                                        <label>MM</label>
                                          <input type="text" placeholder="" className="form-control" id="cvv"/>
                                      </div>
                                      <div className="form-group form_box CVV">
                                        <label>YY</label>
                                          <input type="text" placeholder="" className="form-control" id="cvv"/>
                                      </div>
                                      <div className="form-group form_box CVV"   style={{margin:'0'}}>
                                        <label>CVV</label>
                                          <input type="text" placeholder="" className="form-control" id="cvv"/>
                                      </div>
                                      <div className="card_valid_info">
                                        <p>To verify your card, a samll amount will be charged to it. After verfication the amount will be automatically refunded.</p>
                                      </div>
                                      <div className="row" style={{clear:'both'}}>
                                        <div className="col-sm-6 sidegapp">
                                          <div className="form-group" id="pay-now">
                                              <div className="order_button">
                                                  <button type="submit" id="confirm-purchase">ADD CARD</button>
                                              </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6 sidegapp ">
                                            <div className="form-group" id="credit_cards">
                                              <div className="form-group" id="credit_cards">
                                              <img src={visa} id="visa"/>
                                              <img src={mastercard} id="mastercard" />
                                              <img src={amex} id="amex"/>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
                  
                </div>
              </div>

            </div>
          </div>
        </div>
</section>

          <Footer />
        </React.Fragment>
    )
}
}
export default AddCard;
