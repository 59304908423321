import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import footerlogo  from '../assets/images/clud-grocer.png';
import car  from '../assets/images/car.png';
import chash2  from '../assets/images/chash2.png';
import order1  from '../assets/images/order1.png';
import Header from './Header';
import Footer from './Footer';
import $ from 'jquery';
class ChangeLocation extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){
$(".membership_plans .select_plans button").click(function(){
  $(".hide_membership").hide();
  $(".membership_months").show();
});

$(".wallet_store button").click(function(){
  $(".congrats_info").show();
  $(".membership_months").hide();
});

}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="white_shadow_box" id="vegetables">
                <div className="hide_membership">
                  <div className="membership">
                  <img src={footerlogo} alt=""/>
                  <h4>Select the plan which suits your needs</h4>
                </div>
                <div className="membership_plans">
                  <ul>
                    <li>
                      <div className="select_plans">
                        <b>3 Months</b>
                        <p className="line-through">499 PKR</p>
                        <p>299 PKR</p>
                        <button type="button">Select</button>
                      </div>
                    </li>
                    <li>
                      <div className="select_plans">
                        <b>6 Months</b>
                        <p className="line-through">799 PKR</p>
                        <p>499 PKR</p>
                        <button type="button">Select</button>
                      </div>
                    </li>
                    <li>
                      <div className="select_plans">
                        <b>12 Months</b>
                        <p className="line-through">1399 PKR</p>
                        <p>899 PKR</p>
                        <button type="button">Select</button>
                      </div>
                    </li>
                  </ul>
                  <h4>You can cancel anytime within 15 days.</h4>
                </div>
                </div>


                <div className="membership_months">
                  <div className="membership">
                  <h4>Membership Summary</h4>
                </div>
                <div className="membership_summary">
                  <div className="row">
                    <div className="col-sm-6 months_plan">
                      <h6>3 Months membership plan</h6>
                    </div>
                    <div className="col-sm-6 plans_values">
                      <p>Rs. 499</p>
                      <h6>Rs. 299</h6>
                      <h6 className="green_value">You save Rs 200</h6>
                    </div>
                  </div>
                  <div className="wallet_store">
                  <button type="button">PAY WITH ORDER EXPRESS Wallet</button>
                  <p>Pay for Order Express with grocerapp wallet. Any negative balance will be adjusted with your next order. Clear negative balance within 30 days to enjoy uninterrupted membership. The plan will auto-resubscribe when its duration will end.</p>
                </div>
                </div>
                </div>

                <div className="congrats_info">
                  <p>Congrats asdfa, start using your GrocerClub benefits!</p>
                  <h4>Enjoy your GrocerClub benefits for 3 Months</h4>
                </div>
              </div>
            </div>
          </section>



          <section className="slider_warp">
            <div className="container">
                <div className="slider_head category_head">
                  <h3>Membership Features</h3>
              </div>
              <div className="white_shadow_box" id="vegetables">
                <div className="row">
                  <div className="col-sm-3 delivery_box">
                    <div className="Features_member">
                      <img src={car} alt=""/>
                      <b>No Delivery Charge</b>
                      <p>On order above 299</p>
                    </div>
                  </div>
                  <div className="col-sm-4 delivery_box">
                    <div className="Features_member">
                      <img src={chash2} alt=""/>
                      <b>Exclusive Promo Codes</b>
                      <p>Save big with lots of member-exclusive promos every month</p>
                    </div>
                  </div>
                  <div className="col-sm-5 delivery_box">
                    <div className="Features_member">
                      <img src={order1} alt=""/>
                      <b>Customer Support Over Call</b>
                      <p>Talk directly to our customer support team. Why get help over the chat like non-members?</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="slider_warp">
              <div className="container">
                  <div className="slider_head category_head">
                    <h3>FAQs</h3>
                </div>
                <div className="white_shadow_box" id="vegetables">
                  <div className="panel-group" id="accordionGroupClosed" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href="#collapseCloseOne" aria-expanded="true" aria-controls="collapseCloseOne">How many orders can I place in a month?</a>
                        </h4>
                      </div>
                      <div id="collapseCloseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">Because we value our customers so much, we are giving you unlimited orders throughout the month without any delivery charges.</div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href="#collapseCloseTwo" aria-expanded="false" aria-controls="collapseTwo">Can I use the same membership accounts for different locations?</a>
                        </h4>
                      </div>
                      <div id="collapseCloseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div className="panel-body">Place your orders at your convince and without the worry of a specific address. Just make sure you update the location prior to placing the order.</div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingThree">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href="#collapseCloseThree" aria-expanded="false" aria-controls="collapseThree">How do I resubscribe once the membership expires?</a>
                        </h4>
                      </div>
                      <div id="collapseCloseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div className="panel-body">Don't worry, as always, we have you covered. Your current plan will automatically resubscribe at the end of the plan duration. If you wish to cancel your membership, you can do that within 15 days of activation/renewal.</div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingFour">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href="#collapseCloseThree" aria-expanded="false" aria-controls="collapseThree">I changed my mind, how do I cancel?</a>
                        </h4>
                      </div>
                      <div id="collapseCloseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                        <div className="panel-body">Cancellations are super easy, simply hit the cancel button on membership detail page within 15 days of the plan purchase for a full refund. Please note, you will be charged for all deliveries as per our regular policy on the orders placed using the plan.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


          <Footer />
        </React.Fragment>
    )
}
}
export default ChangeLocation;
