import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import grocerydawn  from '../assets/images/grocerapp-dawn-603ff1de99e5d.webp';
import groceryssw  from '../assets/images/grocerapp-ssw-promo-603bf35dcb338.webp';
import grocerychicken  from '../assets/images/grocerapp-chicken-603ff206f1e54.webp';
import groceryvege  from '../assets/images/grocerapp-vegetables-5ef9de16132b7.webp';
import groceryfruits  from '../assets/images/grocerapp-fruits-5ef9d56eb2cdb.webp';
import bannerone  from '../assets/images/banner-sliderone.jpg';
import bannertwo  from '../assets/images/slider-ONION.jpg';
import bannerthree  from '../assets/images/slider-banner-2.jpg';

import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
import {api_url} from '../Config';
class Home extends Component {

  constructor(){
super();
    this.state = {
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      products:[],
      mainCategory:[],
    }
  }
componentDidUpdate(){
    function Ant(crslId) {
    let id = document.getElementById(crslId);
    if(id) {
      this.crslRoot = id
    }
    else {
      this.crslRoot = document.querySelector('.ant-carousel')
    };

    // Carousel objects
    this.crslList = this.crslRoot.querySelector('.ant-carousel-list');
    this.crslElements = this.crslList.querySelectorAll('.ant-carousel-element');
    this.crslElemFirst = this.crslList.querySelector('.ant-carousel-element');
    this.leftArrow = this.crslRoot.querySelector('div.ant-carousel-arrow-left');
    this.rightArrow = this.crslRoot.querySelector('div.ant-carousel-arrow-right');
    this.indicatorDots = this.crslRoot.querySelector('div.ant-carousel-dots');

    // Initialization
    this.options = Ant.defaults;
    Ant.initialize(this)
  };

  Ant.defaults = {

    // Default options for the carousel
    elemVisible: 1,
    loop: true,
    auto: true,
    interval: 5000,
    speed: 1750,
    touch: true,
    arrows: true,
    dots: false
  };

  Ant.prototype.elemPrev = function(num) {
    num = num || 1;

    if(this.options.dots) this.dotOn(this.currentElement);
    this.currentElement -= num;
    if(this.currentElement < 0) this.currentElement = this.dotsVisible-1;
    if(this.options.dots) this.dotOff(this.currentElement);

    if(!this.options.loop) {  // сдвиг вправо без цикла
      this.currentOffset += this.elemWidth*num;
      this.crslList.style.marginLeft = this.currentOffset + 'px';
      if(this.currentElement == 0) {
        this.leftArrow.style.display = 'none'; this.touchPrev = false
      }
      this.rightArrow.style.display = 'block'; this.touchNext = true
    }
    else {                    // сдвиг вправо с циклом
      let elm, buf, this$ = this;
      for(let i=0; i<num; i++) {
        elm = this.crslList.lastElementChild;
        buf = elm.cloneNode(true);
        this.crslList.insertBefore(buf, this.crslList.firstElementChild);
        this.crslList.removeChild(elm)
      };
      this.crslList.style.marginLeft = '-' + this.elemWidth*num + 'px';
      let compStyle = window.getComputedStyle(this.crslList).marginLeft;
      this.crslList.style.cssText = 'transition:margin '+this.options.speed+'ms ease;';
      this.crslList.style.marginLeft = '0px';
      setTimeout(function() {
        this$.crslList.style.cssText = 'transition:none;'
      }, this.options.speed)
    }
  };

  Ant.prototype.elemNext = function(num) {
    num = num || 1;

    if(this.options.dots) this.dotOn(this.currentElement);
    this.currentElement += num; if(this.currentElement >= this.dotsVisible) this.currentElement = 0;
    if(this.options.dots) this.dotOff(this.currentElement);

    if(!this.options.loop) {  // сдвиг влево без цикла
      this.currentOffset -= this.elemWidth*num;
      this.crslList.style.marginLeft = this.currentOffset + 'px';
      if(this.currentElement == this.dotsVisible-1) {
        this.rightArrow.style.display = 'none'; this.touchNext = false
      }
      this.leftArrow.style.display = 'block'; this.touchPrev = true
    }
    else {                    // сдвиг влево с циклом
      let elm, buf, this$ = this;
      this.crslList.style.cssText = 'transition:margin '+this.options.speed+'ms ease;';
      this.crslList.style.marginLeft = '-' + this.elemWidth*num + 'px';
      setTimeout(function() {
        this$.crslList.style.cssText = 'transition:none;';
        for(let i=0; i<num; i++) {
          elm = this$.crslList.firstElementChild;
          buf = elm.cloneNode(true); this$.crslList.appendChild(buf);
          this$.crslList.removeChild(elm)
        };
        this$.crslList.style.marginLeft = '0px'
      }, this.options.speed)
    }
  };

  Ant.prototype.dotOn = function(num) {
    this.indicatorDotsAll[num].style.cssText = 'background-color:#BBB; cursor:pointer;'
  };

  Ant.prototype.dotOff = function(num) {
    this.indicatorDotsAll[num].style.cssText = 'background-color:#556; cursor:default;'
  };

  Ant.initialize = function(that) {

    // Constants
    that.elemCount = that.crslElements.length; // Количество элементов
    that.dotsVisible = that.elemCount;         // Число видимых точек
    let elemStyle = window.getComputedStyle(that.crslElemFirst);
    that.elemWidth = that.crslElemFirst.offsetWidth +  // Ширина элемента (без margin)
      parseInt(elemStyle.marginLeft) + parseInt(elemStyle.marginRight);

    // Variables
    that.currentElement = 0; that.currentOffset = 0;
    that.touchPrev = true; that.touchNext = true;
    let xTouch, yTouch, xDiff, yDiff, dragTime;
    let bgTime = getTime();

    // Functions
    function getTime() {
      return new Date().getTime();
    };
    function setAutoScroll() {
      that.autoScroll = setInterval(function() {
        let fnTime = getTime();
        if(fnTime - bgTime + 10 > that.options.interval) {
          bgTime = fnTime; that.elemNext()
        }
      }, that.options.interval)
    };

    // Start initialization
    if(that.elemCount <= that.options.elemVisible) {   // Отключить навигацию
      that.options.auto = false; that.options.touch = false;
      that.options.arrows = false; that.options.dots = false;
      that.leftArrow.style.display = 'none'; that.rightArrow.style.display = 'none'
    };

    if(!that.options.loop) {       // если нет цикла - уточнить количество точек
      that.dotsVisible = that.elemCount - that.options.elemVisible + 1;
      that.leftArrow.style.display = 'none';  // отключить левую стрелку
      that.touchPrev = false;    // отключить прокрутку прикосновением вправо
      that.options.auto = false; // отключить автопркрутку
    }
    else if(that.options.auto) {   // инициализация автопрокруки
      setAutoScroll();
      // Остановка прокрутки при наведении мыши на элемент
      that.crslList.addEventListener('mouseenter', function() {clearInterval(that.autoScroll)}, false);
      that.crslList.addEventListener('mouseleave', setAutoScroll, false)
    };

    if(that.options.touch) {   // инициализация прокрутки прикосновением
      that.crslList.addEventListener('touchstart', function(e) {
        xTouch = parseInt(e.touches[0].clientX);
        yTouch = parseInt(e.touches[0].clientY);
        dragTime = getTime()
      }, false);
      that.crslList.addEventListener('touchmove', function(e) {
        if(!xTouch || !yTouch) return;
        xDiff = xTouch - parseInt(e.touches[0].clientX);
        yDiff = yTouch - parseInt(e.touches[0].clientY);
        if(Math.abs(xDiff) > 15 && Math.abs(xDiff) > Math.abs(yDiff) && getTime() - dragTime < 75) {
          if(that.touchNext && xDiff > 0) {
            bgTime = getTime(); that.elemNext()
          }
          else if(that.touchPrev && xDiff < 0) {
            bgTime = getTime(); that.elemPrev()
          }
        }
      }, false)
    };

    if(that.options.arrows) {  // инициализация стрелок
      if(!that.options.loop) that.crslList.style.cssText = 'transition:margin '+that.options.speed+'ms ease;';
      that.leftArrow.addEventListener('click', function() {
        let fnTime = getTime();
        if(fnTime - bgTime > that.options.speed) {
          bgTime = fnTime; that.elemPrev()
        }
      }, false);
      that.rightArrow.addEventListener('click', function() {
        let fnTime = getTime();
        if(fnTime - bgTime > that.options.speed) {
          bgTime = fnTime; that.elemNext()
        }
      }, false)
    }
    else {
      that.leftArrow.style.display = 'none'; that.rightArrow.style.display = 'none'
    };

    if(that.options.dots) {  // инициализация индикаторных точек
      let sum = '', diffNum;
      for(let i=0; i<that.dotsVisible; i++) {
        sum += '<span class="ant-dot"></span>'
      };
      that.indicatorDots.innerHTML = sum;
      that.indicatorDotsAll = that.crslRoot.querySelectorAll('span.ant-dot');
      // Назначаем точкам обработчик события 'click'
      for(let n=0; n<that.dotsVisible; n++) {
        that.indicatorDotsAll[n].addEventListener('click', function() {
          diffNum = Math.abs(n - that.currentElement);
          if(n < that.currentElement) {
            bgTime = getTime(); that.elemPrev(diffNum)
          }
          else if(n > that.currentElement) {
            bgTime = getTime(); that.elemNext(diffNum)
          }
          // Если n == that.currentElement ничего не делаем
        }, false)
      };
      that.dotOff(0);  // точка[0] выключена, остальные включены
      for(let i=1; i<that.dotsVisible; i++) {
        that.dotOn(i)
      }
    }
  };

  new Ant();
}
componentDidMount(){

  // Component Did Mount Functions



    axios.get(this.state.base_url+'api/grocery-products')
    .then((response) => {

      if (response.status==200) {
        this.setState({
          products:response.data
        },()=>{
          axios.get(this.state.base_url+'api/grocery-main-categories')
          .then((response) => {

            if (response.status==200) {
              this.setState({
                mainCategory:response.data
              })
            }
          });
        })
      }
    });




}

  render(){

    return (

        <React.Fragment>
          <Header />

        <section className="slider_warp">
          <div className="container">
            <div className="white_shadow_box">
              <div className="slider_category">
                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="item active">
                      <div className="banner_img">
                      <img src={grocerydawn} alt=""/>
                        </div>
                    </div>
                    <div className="item">
                      <div className="banner_img">
                          <img src={groceryssw} alt=""/>
                        </div>
                    </div>
                    <div className="item">
                      <div className="banner_img">
                         <img src={grocerychicken} alt=""/>
                        </div>
                    </div>
                  </div>

                  <Link className="left carousel-control" to="#myCarousel" data-slide="prev">
                    <span className="fa fa-angle-left"></span>
                    <span className="sr-only">Previous</span>
                  </Link>
                  <Link className="right carousel-control" to="#myCarousel" data-slide="next">
                    <span className="fa fa-angle-right"></span>
                    <span className="sr-only">Next</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="slider_warp">
          <div className="container">
            <div className="white_shadow_box" id="super_saver">
              <div className="slider_head">
                <h3 className="super_week">Super Saver Week</h3>
              </div>
              <div className="view_all_products">
                <Link to="/category-detail">View all <i className="fa fa-angle-right"></i></Link>
              </div>
              <div className="ant-carousel ant-carousel-one">
                  <div className="ant-carousel-hider">
                    <ul className="ant-carousel-list">


                        {
                            this.state.products.length > 0 &&
                            this.state.products.map(value=>{

                                let discounted_price = value.unit_price;
                                if (value.discount_type=='amount') {
                                    discounted_price = value.unit_price - value.discount;
                                }
                                if (value.discount_type=='percent') {
                                    let new_dis = (value.unit_price * value.discount) /100;
                                    discounted_price = value.unit_price - new_dis;
                                }
                                let percentage = 0;
                                if (value.discount_type=='amount') {
                                    percentage = (value.discount * 100)/value.unit_price;
                                }
                                if (value.discount_type=='percent') {
                                    percentage = value.discount;
                                }
                                var path = this.state.img_url+value.file_name;

                                return(

                                    <li className="ant-carousel-element" key={value.slug}>
                                    <div className="slider_info">
                                      <Link to={`/detail/${value.slug}/${value.category_id}`} className="detail_page">
                                      <div className="percent_off">
                                        <b>{percentage.toFixed(0)}% OFF</b>
                                      </div>
                                      <img src={path} alt=""/>
                                        <h4>{value.name}</h4>
                                        <p>{value.unit}</p>
                                        <h6>Rs. {value.unit_price.toFixed(2)} <span>Rs. {discounted_price.toFixed(2)}</span></h6>
                                      </Link>
                                      <Link className="click_cart order-ready-btn">Add To Cart</Link>
                                      <div className="quantity_box get_order_btn current" data-tab="tab-1" id="add_minus_qty">
                                         <input type="button" value="-" className="qty-minus minus_plus"/>
                                         <input type="text" className="value_box" value="1"/>
                                         <input type="button" value="+" className="qty-plus minus_plus"/>
                                      </div>
                                      </div>
                                    </li>

                                    )
                            })
                        }

                    </ul>
                  </div>
                  <div className="ant-carousel-arrow-left"></div>
                  <div className="ant-carousel-arrow-right"></div>
              </div>
            </div>
          </div>
        </section>

        <section className="slider_warp">
          <div className="container">
            <div className="white_shadow_box colums_two" id="vegetables">
              <div className="row">
                <div className="col-sm-4 add_banner">
                  <img src={bannerone} alt=""/>
                </div>
                <div className="col-sm-8 add_banner">
                  <div className="info_add">
                    <h3>Ap ne Jo Bhi Sabzi Banani Hai <span>Order Express</span> Se he <span>Deliver</span> Karwani Hai</h3>
                    <p>Call Now</p>
                    <Link to="tel:0300-111-73-07"><i className="fa fa-phone"></i> 0300-111-73-07</Link>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 add_banner_info">
                      <img src={bannertwo} alt=""/>
                    </div>
                    <div className="col-sm-6 add_banner_info">
                      <img src={bannerthree} alt=""/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>


        {this.state.mainCategory.length > 0 &&
            this.state.mainCategory.map((value,index)=>{
                return(

                    <section className="slider_warp" key={index}>
                      <div className="container">
                        <div className="white_shadow_box colums_two" id="vegetables">
                          <div className="slider_head orange_bg">
                            <h3>{value.name}</h3>
                            <p>{value.meta_description}</p>
                          </div>
                          <div className="slider_category">
                            <ul>
                                {
                                    value.sub_category_array!=='' &&
                                    value.sub_category_array.map((value,index)=>{
                                        var path = this.state.img_url+value.file_name;
                                        return(
                                            <li key={index}>
                                            <Link to={`/category-detail/${value.slug}`} className="detail_page">
                                            <div className="slider_info">
                                              <img src={path} alt=""/>
                                              <h4>{value.name}</h4>
                                            </div>
                                          </Link>
                                          </li>

                                        )
                                    })
                                }

                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>

                )
            })}



          <Footer />
        </React.Fragment>
    )
}
}
export default Home;
