import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class TrackOrder extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){

}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="white_shadow_box" id="vegetables">
                <div className="my_profile track_order">
                  <div className="profile_">
                    <h4>Track Your Order</h4>
                    <form action="" method="">
                      <div className="form_box">
                      <input type="text" value="" placeholder=""/>
                      <Link to="/order-details"><button type="button" className="use_call">View Your Status</button></Link>
                    </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default TrackOrder;
