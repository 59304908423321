import React from 'react'
import { Link,  } from 'react-router-dom';
import footerlogo  from '../assets/images/clud-grocer.png';
import mastercard  from '../assets/images/mastercard.png';
import visa  from '../assets/images/Visa.png';
import paypal  from '../assets/images/paypal-icon-5cut.png';
import klarnablue  from '../assets/images/klarnablue.png';
import sepa  from '../assets/images/sepa_uberweisungcut.png';
const Footer = () => {
    return (
        <section className="footer_wrap">
          <div className="container">
              <div className="white_shadow_box">
                  <div className="footer_box">
                    <ul>
                      <li className="footer_logo" id="footer_logo">
                        <div className="footer_inner ">
                          <Link to="index.html"><img src={footerlogo} alt=""/> 
                          </Link>
                        </div>
                      </li>
                      <li className="useful_links">
                        <div className="footer_inner ">
                          <h3>Useful Links</h3>
                          <Link to="/">Home</Link>
                          <Link to="/categories">Categories</Link>
                          <Link to="/myorders">My Orders</Link>
                          <Link to="/membership">Order Express</Link>
                          <Link to="/wallet">Wallet</Link>
                          <Link to="#">Privacy Policy</Link>
                          <Link to="#">Terms & Conditions</Link>
                        </div>
                      </li>
                      <li className="useful_links">
                        <div className="footer_inner ">
                          <h3>Categories</h3>
                          <Link to="#">Fruits & Vegetables</Link>
                          <Link to="#">Fresh Meat</Link>
                          <Link to="#">Fish & Seafood</Link>
                          <Link to="#">Grocery</Link>
                          <Link to="#">Dry Fruit & Nuts</Link>
                          <Link to="#">Home Care</Link>
                          <Link to="#">Baby Care</Link>
                        </div>
                      </li>
                      <li>
                        <div className="footer_inner">
                          <h3>Top Brands</h3>
                          <Link to="#">Unilever (115)</Link>
                          <Link to="#">Ladies Night (113)</Link>
                          <Link to="#">Reckitt (104)</Link>
                          <Link to="#">Super Saver Week (91)</Link>
                          <Link to="#">Nestle (81)</Link>
                          <Link to="#">Dawn (52)</Link>
                          <Link to="#">Colgate (27)</Link>
                        </div>
                      </li>
                      <li>
                        <div className="footer_inner">
                          <h3>Payment Options</h3>
                          <div className="payment_method">
                              <Link to="#" target="_blank"><img src={mastercard} alt=""/></Link>
                              <Link to="#" target="_blank"><img src={visa} alt=""/></Link>
                              <Link to="#" target="_blank"><img src={paypal} alt=""/></Link>
                              <Link to="#" target="_blank"><img src={klarnablue} alt=""/></Link>
                              <Link to="#" target="_blank"><img src={sepa} alt=""/></Link>
                          </div>
                          <div className="search_box">
                            <form action="" method="">
                              <div className="search_form">
                                <input className="jss508 jss496" placeholder="Search" type="text" value=""/>
                                <Link to="/category-detail"><svg   viewBox="0 0 24 24"><path d="M9.5 4a6.5 6.5 0 0 1 4.932 10.734l5.644 5.644l-.707.707l-5.645-5.645A6.5 6.5 0 1 1 9.5 4zm0 1a5.5 5.5 0 1 0 0 11a5.5 5.5 0 0 0 0-11z" fill="#fff"/></svg></Link>
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
      </section>
    )
}

export default Footer
