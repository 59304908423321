import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
import {api_url} from '../Config';
import axios from "axios";

class Categories extends Component {

  constructor(){
super();
    this.state = {
      productDetail:[],
      relatedProduct:[],
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      mainCategory:[],
    }
  }

componentDidMount(){
  axios.get(this.state.base_url+'api/grocery-main-categories')
    .then((response) => {

      if (response.status==200) {
        this.setState({
          mainCategory:response.data
        })
      }
    });
}

  render(){

    return (

        <React.Fragment>
          <Header />

        {this.state.mainCategory.length > 0 &&
            this.state.mainCategory.map((value,index)=>{
                return(

                    <section className="slider_warp" key={index}>
                      <div className="container">
                        <div className="white_shadow_box colums_two" id="vegetables">
                          <div className="slider_head orange_bg">
                            <h3>{value.name}</h3>
                            <p>{value.meta_description}</p>
                          </div>
                          <div className="slider_category">
                            <ul>
                                {
                                    value.sub_category_array!=='' &&
                                    value.sub_category_array.map((value,index)=>{
                                        var path = this.state.img_url+value.file_name;
                                        return(
                                            <li key={index}>
                                            <Link to={`/category-detail/${value.slug}`} className="detail_page">
                                            <div className="slider_info">
                                              <img src={path} alt=""/>
                                              <h4>{value.name}</h4>
                                            </div>
                                          </Link>
                                          </li>

                                        )
                                    })
                                }

                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>

                )
            })}

          <Footer />
        </React.Fragment>
    )
}
}
export default Categories;
