import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";

import Header from './Header';
import Footer from './Footer';
import {api_url} from '../Config';
class Detail extends Component {

  constructor(){
super();
    this.state = {
      productDetail:[],
      relatedProduct:[],
      base_url:api_url.base_url,
      img_url:api_url.img_url,
    }
  }
componentDidUpdate(){
    function Ant(crslId) {
    let id = document.getElementById(crslId);
    if(id) {
      this.crslRoot = id
    }
    else {
      this.crslRoot = document.querySelector('.ant-carousel')
    };

    // Carousel objects
    this.crslList = this.crslRoot.querySelector('.ant-carousel-list');
    this.crslElements = this.crslList.querySelectorAll('.ant-carousel-element');
    this.crslElemFirst = this.crslList.querySelector('.ant-carousel-element');
    this.leftArrow = this.crslRoot.querySelector('div.ant-carousel-arrow-left');
    this.rightArrow = this.crslRoot.querySelector('div.ant-carousel-arrow-right');
    this.indicatorDots = this.crslRoot.querySelector('div.ant-carousel-dots');

    // Initialization
    this.options = Ant.defaults;
    Ant.initialize(this)
  };

  Ant.defaults = {

    // Default options for the carousel
    elemVisible: 1,
    loop: true,
    auto: true,
    interval: 5000,
    speed: 1750,
    touch: true,
    arrows: true,
    dots: false
  };

  Ant.prototype.elemPrev = function(num) {
    num = num || 1;

    if(this.options.dots) this.dotOn(this.currentElement);
    this.currentElement -= num;
    if(this.currentElement < 0) this.currentElement = this.dotsVisible-1;
    if(this.options.dots) this.dotOff(this.currentElement);

    if(!this.options.loop) {  // сдвиг вправо без цикла
      this.currentOffset += this.elemWidth*num;
      this.crslList.style.marginLeft = this.currentOffset + 'px';
      if(this.currentElement == 0) {
        this.leftArrow.style.display = 'none'; this.touchPrev = false
      }
      this.rightArrow.style.display = 'block'; this.touchNext = true
    }
    else {                    // сдвиг вправо с циклом
      let elm, buf, this$ = this;
      for(let i=0; i<num; i++) {
        elm = this.crslList.lastElementChild;
        buf = elm.cloneNode(true);
        this.crslList.insertBefore(buf, this.crslList.firstElementChild);
        this.crslList.removeChild(elm)
      };
      this.crslList.style.marginLeft = '-' + this.elemWidth*num + 'px';
      let compStyle = window.getComputedStyle(this.crslList).marginLeft;
      this.crslList.style.cssText = 'transition:margin '+this.options.speed+'ms ease;';
      this.crslList.style.marginLeft = '0px';
      setTimeout(function() {
        this$.crslList.style.cssText = 'transition:none;'
      }, this.options.speed)
    }
  };

  Ant.prototype.elemNext = function(num) {
    num = num || 1;

    if(this.options.dots) this.dotOn(this.currentElement);
    this.currentElement += num; if(this.currentElement >= this.dotsVisible) this.currentElement = 0;
    if(this.options.dots) this.dotOff(this.currentElement);

    if(!this.options.loop) {  // сдвиг влево без цикла
      this.currentOffset -= this.elemWidth*num;
      this.crslList.style.marginLeft = this.currentOffset + 'px';
      if(this.currentElement == this.dotsVisible-1) {
        this.rightArrow.style.display = 'none'; this.touchNext = false
      }
      this.leftArrow.style.display = 'block'; this.touchPrev = true
    }
    else {                    // сдвиг влево с циклом
      let elm, buf, this$ = this;
      this.crslList.style.cssText = 'transition:margin '+this.options.speed+'ms ease;';
      this.crslList.style.marginLeft = '-' + this.elemWidth*num + 'px';
      setTimeout(function() {
        this$.crslList.style.cssText = 'transition:none;';
        for(let i=0; i<num; i++) {
          elm = this$.crslList.firstElementChild;
          buf = elm.cloneNode(true); this$.crslList.appendChild(buf);
          this$.crslList.removeChild(elm)
        };
        this$.crslList.style.marginLeft = '0px'
      }, this.options.speed)
    }
  };

  Ant.prototype.dotOn = function(num) {
    this.indicatorDotsAll[num].style.cssText = 'background-color:#BBB; cursor:pointer;'
  };

  Ant.prototype.dotOff = function(num) {
    this.indicatorDotsAll[num].style.cssText = 'background-color:#556; cursor:default;'
  };

  Ant.initialize = function(that) {

    // Constants
    that.elemCount = that.crslElements.length; // Количество элементов
    that.dotsVisible = that.elemCount;         // Число видимых точек
    let elemStyle = window.getComputedStyle(that.crslElemFirst);
    that.elemWidth = that.crslElemFirst.offsetWidth +  // Ширина элемента (без margin)
      parseInt(elemStyle.marginLeft) + parseInt(elemStyle.marginRight);

    // Variables
    that.currentElement = 0; that.currentOffset = 0;
    that.touchPrev = true; that.touchNext = true;
    let xTouch, yTouch, xDiff, yDiff, dragTime;
    let bgTime = getTime();

    // Functions
    function getTime() {
      return new Date().getTime();
    };
    function setAutoScroll() {
      that.autoScroll = setInterval(function() {
        let fnTime = getTime();
        if(fnTime - bgTime + 10 > that.options.interval) {
          bgTime = fnTime; that.elemNext()
        }
      }, that.options.interval)
    };

    // Start initialization
    if(that.elemCount <= that.options.elemVisible) {   // Отключить навигацию
      that.options.auto = false; that.options.touch = false;
      that.options.arrows = false; that.options.dots = false;
      that.leftArrow.style.display = 'none'; that.rightArrow.style.display = 'none'
    };

    if(!that.options.loop) {       // если нет цикла - уточнить количество точек
      that.dotsVisible = that.elemCount - that.options.elemVisible + 1;
      that.leftArrow.style.display = 'none';  // отключить левую стрелку
      that.touchPrev = false;    // отключить прокрутку прикосновением вправо
      that.options.auto = false; // отключить автопркрутку
    }
    else if(that.options.auto) {   // инициализация автопрокруки
      setAutoScroll();
      // Остановка прокрутки при наведении мыши на элемент
      that.crslList.addEventListener('mouseenter', function() {clearInterval(that.autoScroll)}, false);
      that.crslList.addEventListener('mouseleave', setAutoScroll, false)
    };

    if(that.options.touch) {   // инициализация прокрутки прикосновением
      that.crslList.addEventListener('touchstart', function(e) {
        xTouch = parseInt(e.touches[0].clientX);
        yTouch = parseInt(e.touches[0].clientY);
        dragTime = getTime()
      }, false);
      that.crslList.addEventListener('touchmove', function(e) {
        if(!xTouch || !yTouch) return;
        xDiff = xTouch - parseInt(e.touches[0].clientX);
        yDiff = yTouch - parseInt(e.touches[0].clientY);
        if(Math.abs(xDiff) > 15 && Math.abs(xDiff) > Math.abs(yDiff) && getTime() - dragTime < 75) {
          if(that.touchNext && xDiff > 0) {
            bgTime = getTime(); that.elemNext()
          }
          else if(that.touchPrev && xDiff < 0) {
            bgTime = getTime(); that.elemPrev()
          }
        }
      }, false)
    };

    if(that.options.arrows) {  // инициализация стрелок
      if(!that.options.loop) that.crslList.style.cssText = 'transition:margin '+that.options.speed+'ms ease;';
      that.leftArrow.addEventListener('click', function() {
        let fnTime = getTime();
        if(fnTime - bgTime > that.options.speed) {
          bgTime = fnTime; that.elemPrev()
        }
      }, false);
      that.rightArrow.addEventListener('click', function() {
        let fnTime = getTime();
        if(fnTime - bgTime > that.options.speed) {
          bgTime = fnTime; that.elemNext()
        }
      }, false)
    }
    else {
      that.leftArrow.style.display = 'none'; that.rightArrow.style.display = 'none'
    };

    if(that.options.dots) {  // инициализация индикаторных точек
      let sum = '', diffNum;
      for(let i=0; i<that.dotsVisible; i++) {
        sum += '<span class="ant-dot"></span>'
      };
      that.indicatorDots.innerHTML = sum;
      that.indicatorDotsAll = that.crslRoot.querySelectorAll('span.ant-dot');
      // Назначаем точкам обработчик события 'click'
      for(let n=0; n<that.dotsVisible; n++) {
        that.indicatorDotsAll[n].addEventListener('click', function() {
          diffNum = Math.abs(n - that.currentElement);
          if(n < that.currentElement) {
            bgTime = getTime(); that.elemPrev(diffNum)
          }
          else if(n > that.currentElement) {
            bgTime = getTime(); that.elemNext(diffNum)
          }
          // Если n == that.currentElement ничего не делаем
        }, false)
      };
      that.dotOff(0);  // точка[0] выключена, остальные включены
      for(let i=1; i<that.dotsVisible; i++) {
        that.dotOn(i)
      }
    }
  };

  new Ant();
}
componentDidMount(){
axios.get(this.state.base_url+'api/get-related-product/'+this.props.match.params.cate_id)
    .then((response) => {

      if (response.status==200) {
        this.setState({
          relatedProduct:response.data
        },()=>{
         axios.get(this.state.base_url+'api/get-single-product-detail/'+this.props.match.params.productslug)
          .then((response) => {

            if (response.status==200) {
              this.setState({
                productDetail:response.data
              })
            }
          });
        })
      }
    });

  


}

mountComponent(slug,cate_id)
{
    axios.get(this.state.base_url+'api/get-related-product/'+cate_id)
    .then((response) => {

      if (response.status==200) {
        this.setState({

          relatedProduct:response.data
        },()=>{
         axios.get(this.state.base_url+'api/get-single-product-detail/'+slug)
          .then((response) => {

            if (response.status==200) {
              this.setState({
                productDetail:response.data
              })
            }
          });
        })
      }
    });        
}

  render(){
      const {productDetail, relatedProduct} = this.state;
      let discounted_price = productDetail.unit_price;
        if (productDetail.discount_type=='amount') {
            discounted_price = productDetail.unit_price - productDetail.discount;
        }
        if (productDetail.discount_type=='percent') {
            let new_dis = (productDetail.unit_price * productDetail.discount) /100;
            discounted_price = productDetail.unit_price - new_dis;
        }
        let percentage = 0;
        if (productDetail.discount_type=='amount') {
            percentage = (productDetail.discount * 100)/productDetail.unit_price;
        }
        if (productDetail.discount_type=='percent') {
            percentage = productDetail.discount;
        }

    return (

        <React.Fragment>
          <Header />


          <section className="slider_warp">
            <div className="container">
              <div className="white_shadow_box">
              <div className="breadcrumb_items">
              <ul>
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">›</Link></li>
                <li><Link to="#">Fruits &amp; Vegetables</Link></li>
              </ul>
              <h4>Vegetables</h4>
            </div>
                <div className="row">
                  <div className="col-sm-5 category_tumbnail">
                    <div className="percent_off">
                        <b>{percentage}% OFF</b>
                      </div>
                    <img src={this.state.img_url+productDetail.file_name} alt=""/>
                  </div>
                  <div className="col-sm-7 category_info_box">
                      <div className="slider_info">
                        <h4>{productDetail.name}</h4>
                        <p>Available In: {productDetail.unit}</p>
                        <h6>Rs. {discounted_price} <span>Rs. {productDetail.unit_price}</span></h6>
                        <div className="quantity_box">
                           <input type="button" value="-" className="qty-minus minus_plus"/>
                           <input type="text" className="value_box" value="1"/>
                           <input type="button" value="+" className="qty-plus minus_plus"/>
                        </div>
                        <Link to="/cart">Add To Cart</Link>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        <section className="slider_warp">
          <div className="container">
            <div className="white_shadow_box" id="super_saver">

              <div className="slider_head">
                <h3 className="super_week">Related Products</h3>
              </div>
              <div className="view_all_products">
                <Link to="/category-detail">View all <i className="fa fa-angle-right"></i></Link>
              </div>
              <div className="ant-carousel ant-carousel-one">
                  <div className="ant-carousel-hider">
                    <ul className="ant-carousel-list">


                        {
                            this.state.relatedProduct.length > 0 &&
                            this.state.relatedProduct.map(value=>{

                                let discounted_price = value.unit_price;
                                if (value.discount_type=='amount') {
                                    discounted_price = value.unit_price - value.discount;
                                }
                                if (value.discount_type=='percent') {
                                    let new_dis = (value.unit_price * value.discount) /100;
                                    discounted_price = value.unit_price - new_dis;
                                }
                                let percentage = 0;
                                if (value.discount_type=='amount') {
                                    percentage = (value.discount * 100)/value.unit_price;
                                }
                                if (value.discount_type=='percent') {
                                    percentage = value.discount;
                                }
                                var path = this.state.img_url+value.file_name;

                                return(

                                    <li className="ant-carousel-element" key={value.slug}>
                                    <div className="slider_info">
                                      <Link onClick={()=>this.mountComponent(value.slug,value.category_id)}  className="detail_page">
                                      <div className="percent_off">
                                        <b>{percentage.toFixed(0)}% OFF</b>
                                      </div>
                                      <img src={path} alt=""/>
                                        <h4>{value.name}</h4>
                                        <p>{value.unit}</p>
                                        <h6>Rs. {value.unit_price.toFixed(2)} <span>Rs. {discounted_price.toFixed(2)}</span></h6>
                                      </Link>
                                      <Link className="click_cart order-ready-btn">Add To Cart</Link>
                                      <div className="quantity_box get_order_btn current" data-tab="tab-1" id="add_minus_qty">
                                         <input type="button" value="-" className="qty-minus minus_plus"/>
                                         <input type="text" className="value_box" value="1"/>
                                         <input type="button" value="+" className="qty-plus minus_plus"/>
                                      </div>
                                      </div>
                                    </li>

                                    )
                            })
                        }

                    </ul>
                  </div>
                  <div className="ant-carousel-arrow-left"></div>
                  <div className="ant-carousel-arrow-right"></div>
              </div>
            </div>
          </div>
        </section>


          <Footer />
        </React.Fragment>
    )
}
}
export default Detail;
