import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class ChangeLocation extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){
$(".canvas_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".profile_stepone button").click(function(){
  $(".profile_stepone").hide();
  $(".profile_steptwo").show();
});

$(".profile_steptwo button").click(function(){
  $(".profile_steptwo").hide();
  $(".profile_stethree").show();
});

$(".profile_stethree button").click(function(){
  $(".profile_stethree").hide();
  $(".profile_stepfour").show();
});

$(".profile_stepfour .use_call").click(function(){
  $(".profile_stethree").show();
  $(".profile_stepfour").hide();
});


const x = 1.1; 

var display = document.querySelector('#clock'); 

clock_initialize(x);

function newInterval(x){
        $('#clock').css({"animation" : "none"});
        $('#clock').css({"color":"yellowgreen"});
        clock_initialize(x);
    }


function clock_initialize(x) {

        var count_from_minutes = x; 
        if ( count_from_minutes > 1440) {
             count_from_minutes = 1440;
        }
       let  timer_time = 25 * count_from_minutes;
        let time_at_start = x;


    }
function startTimer($timer_time, display, $time_at_start){

  let interval = setInterval(function () {

            let days = Math.floor($timer_time /86400);
            let hours = Math.floor(($timer_time -(days * 86400)) / 3600);
            let minutes = Math.floor(($timer_time - (days * 86400) - (hours * 3600)) / 60);
            let secs = Math.floor(($timer_time - (days * 86400) - (hours * 3600) - (minutes * 60)));

           
            secs = secs < 10 ? "" + secs : secs;

         
            display.innerHTML = "<div class=\"seconds\">"+ secs +"</div>";
                               
                                

            $timer_time--;
            if($timer_time <= 10){
                $('#clock').css({"color":"red"});
            }
            if($timer_time >= -1 && $timer_time < 10 ){
                $('.seconds').css({"animation" : "scale-secs 1s infinite", "animation-iteration-count": "10"});
            }
            if($timer_time === -1){
                clearInterval(interval);
                $('#clock').css({"animation" : "countdown-over .9s infinite", "animation-iteration-count": "3"});
                $('.seconds').css({"animation" : "none"});
                setTimeout(function(){
                           $('.confirm-btn').css({"animation" : "confirm-come 1s", "display" : "flex"})
            },2800); 
              // setTimeout(function() {newInterval($time_at_start)},2900);
               $('.confirm-btn').on('click',function(){
                          $('.confirm-btn').css({"animation" : "confirm-go 1s"});    
                 setTimeout(function(){
                           $('.confirm-btn').css({ "display" : "none"})
            },1000); 
                      newInterval($time_at_start);
                                    });  
             
              
            }
            return $timer_time;
        },1000);
}



}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="white_shadow_box" id="vegetables">
                <div className="my_profile">
                  <div className="profile_stepone">
                    <h4>Hi, we need your phone number to verify your identity</h4>
                    <div className="form_box">
                      <label>Phone Number</label>
                      <input type="text" value="" placeholder="+92 - "/>
                      <h6>Phone number must start with 3 eg: 3xxxxxxxxx</h6>
                      <button type="button">Use SMS</button>
                      <button type="button" className="use_call">Use Call</button>
                      <p>*You will receive a call/sms shortly. Existing users will log in right after verifying the confirmation code</p>
                    </div>
                  </div>


                  <div className="profile_steptwo">
                    <h4>Enter the send 6 digit code</h4>
                    <h5>+92 - 302839192</h5>
                    <div className="form_box">
                      <label>6 digit code</label>
                      <input type="text" value="" placeholder=""/>
                      <button type="button" className="use_call">NEXT</button>
                      <div className="verifying_mobile">
                        <div className="bundle"><b>0:</b>
                          <div id="clock"></div>
                          <div className="confirm-btn"></div>  
                        </div>  
                        <h5>Didn't receive SMS?</h5>
                        <p>Please verfiy your mobile number by sending 'MNP' in an SMS to 99095, and then retry SMS verification</p>
                      </div>
                    </div>
                  </div>

                  <div className="profile_stethree">
                    <h4>Create your Order Express Account</h4>
                    <div className="form_box">
                      <div className="inner_box">
                        <label>Name</label>
                        <input type="text" value="" placeholder=""/>
                      </div>
                      <div className="inner_box">
                        <label>Delivery Address</label>
                        <input type="text" value="" placeholder=""/>
                      </div>
                      <div className="inner_box">
                        <label>Delivery Location on map</label>
                        <input type="text" value="" placeholder=""/>
                      </div>
                      <div className="gendar">
                        <h6>Gender</h6>
                          <input type="radio" id="test1" name="radio-group" checked="checked" />
                          <label for="test1">Female</label>
                          <input type="radio" id="test2" name="radio-group"/>
                          <label for="test2">Male</label>
                          <input type="radio" id="test3" name="radio-group"/>
                          <label for="test3">Other</label>
                        <button type="button" className="use_call">SIGN UP</button>
                      </div>
                    </div>
                  </div>


                  <div className="profile_stepfour">
                    <h4>Delivery location</h4>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3372.987221546196!2d72.27823066450415!3d32.285321516201435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sayan%20plaza%20first%20floor%20janoobi%20bazar%20near%20Jauharabad!5e0!3m2!1sen!2s!4v1614927785650!5m2!1sen!2s" width="100%" height="300" style={{margin:'0 0 30px 0',display:'block',border:'0'}} allowfullscreen="" loading="lazy"></iframe>
                    <div className="form_box">
                      <button type="button">Proceed</button>
                      <button type="button" className="use_call">Cancel</button>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default ChangeLocation;
