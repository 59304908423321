import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import grocerydalda  from '../assets/images/grocerapp-dalda-cooking-oil-pouch-carton-5f5c5c56d76e1.webp';
import Header from './Header';
import Footer from './Footer';
import {api_url} from '../Config';
import axios from "axios";
class CategoryDetail extends Component {

  constructor(){
super();
    this.state = {
      categoryDetail:[],
      parentDetail:[],
      allProducts:[],
      base_url:api_url.base_url,
      img_url:api_url.img_url,
    }
  }

componentDidMount(){
axios.get(this.state.base_url+'api/category_details/'+this.props.match.params.cate_slug)
    .then((response) => {

      if (response.status==200) {
        this.setState({
          categoryDetail:response.data.category_info,
          parentDetail:response.data.parent_category,
          allProducts:response.data.products,
        })
      }
    });
}

  render(){

    return (

        <React.Fragment>
          <Header />

        <section className="slider_warp">
          <div className="container">
            <div className="white_shadow_box" id="categories_details_p">
                <div className="breadcrumb_items">
              <ul>
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">›</Link></li>
                <li><Link to="#">{this.state.parentDetail.name}</Link></li>
              </ul>
              <h4>{this.state.categoryDetail.name}</h4>
            </div>
              <ul className="tabs auto_xscroll">
                <li className="tab-link current" data-tab="tab-1">All (21)</li>
                <li className="tab-link" data-tab="tab-2">Cooking Oil (4)</li>
                <li className="tab-link" data-tab="tab-3">Ghee (4)</li>
                <li className="tab-link" data-tab="tab-4">Canola Oil (3)</li>
                <li className="tab-link" data-tab="tab-5">Lotion & Cream (5)</li>
                <li className="tab-link" data-tab="tab-6">Shower Gel (5)</li>
                <li className="tab-link" data-tab="tab-7">SunFlower Oil (2)</li>
                <li className="tab-link" data-tab="tab-8">Shampoo (1)</li>
              </ul>

              <div id="tab-1" className="tab-content current">
                <div className="slider_category">
                <ul>

                {
                            this.state.allProducts.length > 0 &&
                            this.state.allProducts.map(value=>{

                                let discounted_price = value.unit_price;
                                if (value.discount_type=='amount') {
                                    discounted_price = value.unit_price - value.discount;
                                }
                                if (value.discount_type=='percent') {
                                    let new_dis = (value.unit_price * value.discount) /100;
                                    discounted_price = value.unit_price - new_dis;
                                }
                                let percentage = 0;
                                if (value.discount_type=='amount') {
                                    percentage = (value.discount * 100)/value.unit_price;
                                }
                                if (value.discount_type=='percent') {
                                    percentage = value.discount;
                                }
                                var path = this.state.img_url+value.file_name;

                                return(

                                    <li>
                                      <div className="slider_info">
                                      <Link to={`/detail/${value.slug}/${value.category_id}`} className="detail_page">
                                      <div className="percent_off">
                                        <b>{percentage.toFixed(0)}% OFF</b>
                                      </div>
                                      <img src={path} alt=""/>
                                        <h4>{value.name}</h4>
                                        <p>{value.unit}</p>
                                        <h6>Rs. {value.unit_price.toFixed(2)} <span>Rs. {discounted_price.toFixed(2)}</span></h6>
                                      </Link>
                                      <Link className="click_cart order-ready-btn">Add To Cart</Link>
                                      <div className="quantity_box get_order_btn current" data-tab="tab-1" id="add_minus_qty">
                                         <input type="button" value="-" className="qty-minus minus_plus"/>
                                         <input type="text" className="value_box" value="1"/>
                                         <input type="button" value="+" className="qty-plus minus_plus"/>
                                      </div>
                                      </div>
                                    </li>

                                    )
                            })
                        }




                </ul>
              </div>
              </div>


            </div>
          </div>
        </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default CategoryDetail;
