import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import easypaisa  from '../assets/images/easypaisa.png';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class Checkout extends Component {

  constructor(){
super();
    this.state = {
    }
  }

componentDidMount(){

}

  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="slider_head category_head">
                <h3>Checkout</h3>
              </div>
              <div className="white_shadow_box" id="vegetables">
                <div className="table-responsive order_received_box cart_table">
                  <table className="table-box checkout_textarea">
                    <tbody>
                      <tr>
                      <td className="change_address">
                        <h5>Your Delivery Address</h5>
                        <p>357, Block N Shah Jamal Gulberg III, Lahore, Punjab 53700, Pakistan</p>
                      </td>
                      <td className="order_total fee_green"><Link to="/change-location" className="change_info">Change</Link></td>
                    </tr>
                    <tr>
                      <td colspan="12"><input type="text" placeholder="Instructions for delivery team." name=""/></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>


          <section className="slider_warp">
          <div className="container">
            <div className="slider_head category_head">
              <h3>Choose delivery slot</h3>
            </div>
            <div className="white_shadow_box" id="vegetables">
              <div className="table-responsive order_received_box cart_table">
                <table className="table-box slottable">
                  <tbody>
                    <tr>
                    <td>
                      <div className="slots_days">
                        <h4>TOMORROW</h4>
                        <h5>6 MAR</h5>
                      </div>
                    </td>
                    <td>
                      <div className="gendar">
                        <input type="radio" id="test1" name="radio-group" checked=""/>
                        <label for="test1">7:00 AM - 12:30 AM</label>
                        <input type="radio" id="test2" name="radio-group"/>
                        <label for="test2">8:00 AM - 12:30 AM</label>
                        <input type="radio" id="test3" name="radio-group"/>
                        <label for="test3">9:00 AM - 12:30 AM</label>
                        <input type="radio" id="test4" name="radio-group"/>
                        <label for="test4">7:00 AM - 12:30 AM</label>
                    </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="slots_days">
                        <h4>SUN</h4>
                        <h5>7 MAR</h5>
                      </div>
                    </td>
                    <td>
                      <div className="gendar">
                        <input type="radio" id="test5" name="radio-group"/>
                        <label for="test5">7:00 AM - 12:30 AM</label>
                        <input type="radio" id="test6" name="radio-group"/>
                        <label for="test6">8:00 AM - 12:30 AM</label>
                        <input type="radio" id="test7" name="radio-group"/>
                        <label for="test7">9:00 AM - 12:30 AM</label>
                        <input type="radio" id="test8" name="radio-group"/>
                        <label for="test8">7:00 AM - 12:30 AM</label>
                    </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="slots_days">
                        <h4>MON</h4>
                        <h5>8 MAR</h5>
                      </div>
                    </td>
                    <td>
                      <div className="gendar">
                        <input type="radio" id="test9" name="radio-group"/>
                        <label for="test9">7:00 AM - 12:30 AM</label>
                        <input type="radio" id="test10" name="radio-group"/>
                        <label for="test10">8:00 AM - 12:30 AM</label>
                        <input type="radio" id="test11" name="radio-group"/>
                        <label for="test11">9:00 AM - 12:30 AM</label>
                        <input type="radio" id="test12" name="radio-group"/>
                        <label for="test12">7:00 AM - 12:30 AM</label>
                    </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="slots_days">
                        <h4>TUE</h4>
                        <h5>9 MAR</h5>
                      </div>
                    </td>
                    <td>
                      <div className="gendar">
                        <input type="radio" id="test13" name="radio-group"/>
                        <label for="test13">7:00 AM - 12:30 AM</label>
                        <input type="radio" id="test14" name="radio-group"/>
                        <label for="test14">8:00 AM - 12:30 AM</label>
                        <input type="radio" id="test15" name="radio-group"/>
                        <label for="test15">9:00 AM - 12:30 AM</label>
                        <input type="radio" id="test16" name="radio-group"/>
                        <label for="test16">7:00 AM - 12:30 AM</label>
                    </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section className="slider_warp">
        <div className="container">
          <div className="slider_head category_head">
            <h3>Current Payment Method</h3>
          </div>
          <div className="white_shadow_box" id="vegetables">
            <div className="table-responsive order_received_box cart_table">
              <table className="table-box">
                <tbody>
                  <tr>
                  <td style={{FontWeight:'bold'}}> <div className="gendar">
                      <input type="radio" id="test20" name="radio-group"/>
                      <label for="test20">Cash On Delivery (COD) <svg style={{width:'22px'}} viewBox="0 0 24 24"><path d="M11 4h1v2.01c3.29.141 4 1.685 4 2.99h-1c0-1.327-1.178-2-3.5-2c-.82 0-3.5.163-3.5 2.249c0 .872 0 1.86 3.621 2.766l1.606.485C15.76 13.435 16 14.572 16 15.751c0 1.881-1.518 3.093-4 3.235V21h-1v-2.01c-3.29-.141-4-1.685-4-2.99h1c0 1.327 1.178 2 3.5 2c.82 0 3.5-.163 3.5-2.249c0-.872 0-1.86-3.621-2.766L9.773 12.5C7.24 11.565 7 10.428 7 9.249c0-1.881 1.518-3.093 4-3.235V4z" fill="#626262"></path></svg></label>
                  </div> 
                  </td>
                  <td className="order_total fee_green"><svg style={{width:'30px'}} viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#51aa1a"></path></svg></td>
                </tr>
                <tr>
                  <td style={{FontWeight:'bold'}}> <div className="gendar">
                      <input type="radio" id="test21" name="radio-group"/>
                      <label for="test21"> Credit Card <svg style={{width:'22px'}} viewBox="0 0 24 24"><path d="M5 7h3V5l2-2h3l2 2v2h3a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3zm5.414-3L9 5.414V7h5V5.414L12.586 4h-2.172zM5 8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H5z" fill="#626262"></path></svg></label>
                  </div> 
                  </td>
                  <td className="order_total fee_green"><svg style={{width:'30px'}} viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#51aa1a"></path></svg></td>
                </tr>
                <tr>
                  <td style={{FontWeight:'bold'}}> <div className="gendar">
                      <input type="radio" id="test23" name="radio-group"/>
                      <label for="test23"> EasyPaisa <img className="easypasa" src={easypaisa} alt=""/> </label>
                  </div>  </td>
                  <td className="order_total fee_green" style={{FontWeight:'bold',color:"#f44336"}} >Coming Soon</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default Checkout;
