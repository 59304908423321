import React from 'react';
import './App.css';
import {BrowserRouter  as Router, withRouter} from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './container/Home.js';
import AddCard from './container/AddCard.js';
import Cart from './container/Cart.js';
import Categories from './container/Categories.js';
import CategoryDetail from './container/CategoryDetail.js';
import ChangeLocation from './container/ChangeLocation.js';
import Checkout from './container/Checkout.js';
import Detail from './container/Detail.js';
import Login from './container/Login.js';
import Membership from './container/Membership.js';
import Myorders from './container/Myorders.js';
import Myprofile from './container/Myprofile.js';
import OrderDetails from './container/OrderDetails.js';
import TrackOrder from './container/TrackOrder.js';
import Wallet from './container/Wallet.js';
// import NoPage from './container/NoPage';
function App() {
  return (
    <React.Fragment>
      	<Router>
      	<Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/add-card" component={AddCard}/>
          <Route path="/cart" component={Cart}/>
          <Route path="/categories" component={Categories}/>
          <Route path="/category-detail/:cate_slug" component={CategoryDetail}/>
          <Route path="/change-location" component={ChangeLocation}/>
          <Route path="/checkout" component={Checkout}/>
          <Route path="/detail/:productslug/:cate_id" component={Detail}/>
          <Route path="/login" component={Login}/>
          <Route path="/membership" component={Membership}/>
          <Route path="/myorders" component={Myorders}/>
          <Route path="/myprofile" component={Myprofile}/>
          <Route path="/order-details" component={OrderDetails}/>
          <Route path="/trackorder" component={TrackOrder}/>
          <Route path="/wallet" component={Wallet}/>
		</Switch>
		</Router>
    </React.Fragment>
  );
}

export default App;
