import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class OrderDetails extends Component {

  constructor(){
super();
    this.state = {
    }
  }


  render(){

    return (

        <React.Fragment>
          <Header />

          <section className="slider_warp">
            <div className="container">
              <div className="slider_head category_head">
                <h3>Track Order No: 96212225880811</h3>
              </div>
              <div className="white_shadow_box" id="vegetables">
                <section>
                  <div className="wizard">
                      <div className="wizard-inner">
                          <div className="connecting-line"></div>
                          <ul className="nav nav-tabs" role="tablist">

                              <li role="presentation" className="active">
                                  <a href="#step1" data-toggle="tab"  role="tab" >
                                      <span className="round-tab"><svg  viewBox="0 0 24 24"><path d="M16 12a3 3 0 0 1-3-3V5H5a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-6h-4zm-2-3a2 2 0 0 0 2 2h3.586L14 5.414V9zM5 4h9l7 7v7a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zm0 4h6v1H5V8zm0 4h6v1H5v-1zm0 4h13v1H5v-1z" fill="#626262"/></svg></span>
                                  </a>
                              </li>

                              <li role="presentation" className="disabled">
                                  <a href="#step2" data-toggle="tab" role="tab">
                                      <span className="round-tab"><svg  viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#626262"/></svg></span>
                                  </a>
                              </li>
                              <li role="presentation" className="disabled">
                                  <a href="#step3" data-toggle="tab" role="tab">
                                      <span className="round-tab"><svg viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#626262"/></svg></span>
                                  </a>
                              </li>

                              <li role="presentation" className="disabled">
                                  <a href="#complete" data-toggle="tab" role="tab" >
                                      <span className="round-tab"><svg  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#626262"/></svg></span>
                                  </a>
                              </li>
                          </ul>
                      </div>

                      <form role="form" className="order_subtotal">
                          <div className="tab-content">
                              <div className="tab-pane active" role="tabpanel" id="step1">
                                  <div className="table-responsive order_received_box cart_table">
                                    <table className="table-box">
                                      <tbody>
                                        <tr style={{background:'#e3e8eb'}} >
                                        <td className="pdt_name" style={{FontWeight:'bold'}}>Product Name</td>
                                        <td style={{FontWeight:'bold'}}>Quantity</td>
                                        <td style={{FontWeight:'bold'}}>Amount</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Subtotal(5):</td>
                                        <td><b>Rs.7,083</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Packing / Delivery Fee:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Voucher:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Discount:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Paid Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Refund Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Payment:</td>
                                        <td><b>COD</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>City:</td>
                                        <td><b>Lahore</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Order Type:</td>
                                        <td><b>Food</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Delivery Type:</td>
                                        <td><b>Home Delivery</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td><b style={{FontSize:'18px'}} >Total:</b></td>
                                        <td><b style={{FontSize:'18px'}} >Rs.7,083</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <ul className="list-inline pull-right">
                                      <li><button type="button" className="btn btn-primary next-step">Next</button></li>
                                  </ul>
                              </div>
                              <div className="tab-pane" role="tabpanel" id="step2">
                                  <div className="table-responsive order_received_box cart_table">
                                    <table className="table-box">
                                      <tbody>
                                        <tr style={{background:'#e3e8eb'}} >
                                        <td className="pdt_name" style={{FontWeight:'bold'}}>Product Name</td>
                                        <td style={{FontWeight:'bold'}}>Quantity</td>
                                        <td style={{FontWeight:'bold'}}>Amount</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Subtotal(5):</td>
                                        <td><b>Rs.7,083</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Packing / Delivery Fee:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Voucher:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Discount:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Paid Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Refund Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Payment:</td>
                                        <td><b>COD</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>City:</td>
                                        <td><b>Lahore</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Order Type:</td>
                                        <td><b>Food</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Delivery Type:</td>
                                        <td><b>Home Delivery</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td><b style={{FontSize:'18px'}} >Total:</b></td>
                                        <td><b style={{FontSize:'18px'}} >Rs.7,083</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <ul className="list-inline pull-right">
                                      <li><button type="button" className="btn btn-default prev-step">Previous</button></li>
                                      <li><button type="button" className="btn btn-primary next-step">Next</button></li>
                                  </ul>
                              </div>
                              <div className="tab-pane" role="tabpanel" id="step3">
                                  <div className="table-responsive order_received_box cart_table">
                                    <table className="table-box">
                                      <tbody>
                                        <tr style={{background:'#e3e8eb'}} >
                                        <td className="pdt_name" style={{FontWeight:'bold'}}>Product Name</td>
                                        <td style={{FontWeight:'bold'}}>Quantity</td>
                                        <td style={{FontWeight:'bold'}}>Amount</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Subtotal(5):</td>
                                        <td><b>Rs.7,083</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Packing / Delivery Fee:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Voucher:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Discount:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Paid Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Refund Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Payment:</td>
                                        <td><b>COD</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>City:</td>
                                        <td><b>Lahore</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Order Type:</td>
                                        <td><b>Food</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Delivery Type:</td>
                                        <td><b>Home Delivery</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td><b style={{FontSize:'18px'}} >Total:</b></td>
                                        <td><b style={{FontSize:'18px'}} >Rs.7,083</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <ul className="list-inline pull-right">
                                      <li><button type="button" className="btn btn-default prev-step">Previous</button></li>
                                      <li><button type="button" className="btn btn-primary btn-info-full next-step">Next</button></li>
                                  </ul>
                              </div>
                              <div className="tab-pane" role="tabpanel" id="complete">
                                    <div className="thankyou">
                                      <h3>Thank you for   your order <span>Your order details</span></h3>
                                    </div>
                                  <div className="table-responsive order_received_box cart_table">
                                    <table className="table-box">
                                      <tbody>
                                        <tr style={{background:'#e3e8eb'}} >
                                        <td className="pdt_name" style={{FontWeight:'bold'}}>Product Name</td>
                                        <td style={{FontWeight:'bold'}}>Quantity</td>
                                        <td style={{FontWeight:'bold'}}>Amount</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td>Dalda Cooking Oil 1L x5</td>
                                        <td>55</td>
                                        <td>Rs.4,221</td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Subtotal(5):</td>
                                        <td><b>Rs.7,083</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Packing / Delivery Fee:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Voucher:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Discount:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Paid Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Wallet Refund Amt:</td>
                                        <td><b>Rs:0</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Payment:</td>
                                        <td><b>COD</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>City:</td>
                                        <td><b>Lahore</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Order Type:</td>
                                        <td><b>Food</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>Delivery Type:</td>
                                        <td><b>Home Delivery</b></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td><b style={{FontSize:'18px'}} >Total:</b></td>
                                        <td><b style={{FontSize:'18px'}} >Rs.7,083</b></td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                              </div>
                              <div className="clearfix"></div>
                          </div>
                      </form>
                  </div>
              </section>
                
              </div>
            </div>
          </section>

          <Footer />
        </React.Fragment>
    )
}
}
export default OrderDetails;
